import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        padding: "2em",
      }}
    >
      <div style={{ maxWidth: "160px" }}>
        <img
          src="https://d33wubrfki0l68.cloudfront.net/assets/img/b2fa717895a6be2dd0d4a0f7fd32712ee16831dd/josh-standard-trans.png"
          alt="Josh Fry illustration"
        />
      </div>
      <div style={{ maxWidth: "500px" }}>
        <h1>Hello, I'm updating my online presence.</h1>
        <h2>
          I moved to <a href="https://joshfry.dev">joshfry.dev</a>
        </h2>
      </div>
    </div>
  </Layout>
)

export default IndexPage
